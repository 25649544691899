import styled from 'styled-components';

export const Container = styled.div`
    padding: 16px;
    @media (min-width: 1280px) {
        padding: 26px;
    }
`

export const Section = styled.section`
   margin-bottom: 4rem;
`

export const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const Left = styled.div`
        flex: 1 0 250px;
       
`
export const Right = styled.div`
        
        flex: 1 1 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
`

export const BPContainer = styled.ul`
    display: flex;
    flex-direction: column;

`

export const BPWrapper = styled.li`
    display: flex;
    align-items: center;

`

export const BulletPoint = styled.div`
    /* background-color: ${props => props.backgroundColor};   */
    color: #000;  
    border-radius: 100%;
    border: 1px solid #000;
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    `