import React from 'react'

import SEO from "../components/seo"


import Layout from '../components/layout'
import {
  Container
} from '../styles/EinrichtungsplanungStyles';


const Einrichtungsplanung = () => {
  return (
    <Layout>
       <SEO title="Termin vereinbaren"/>
      <Container>
          
          <div style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
        <iframe src="https://bookingflix.com/de/diewohnplaner/1614850466/iframe.html" title="Termin vereinbaren" width="800px" height="600px" frameborder="0"></iframe>
        </div>
      </Container>
    </Layout>
  );
}

export default Einrichtungsplanung;

